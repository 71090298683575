import React from 'react'
import PropTypes from 'prop-types'
import styles from './LegalContent.module.scss'

const classes = {
  content: [
    'mr-auto',
    'ml-auto',
    'px-3',
    'md:px-6',
    'font-sans-display',
    'xl:text-lg',
    'text-red-100',
    //
  ].join(' '),
}

const LegalContent = ({ html }) => {
  return (
    <div
      className={[classes.content, styles.content].join(' ')}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

LegalContent.propTypes = {
  html: PropTypes.string.isRequired,
}

export default LegalContent
