import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { connect } from 'react-redux'
import { setHeaderTheme } from '../actions/headerTheme'
import onPageRender from '../hocs/onPageRender'
import Footer from '../components/Footer/Footer'
import Head from '../components/Head/Head'
import LegalContent from '../components/LegalContent/LegalContent'

const classes = {
  container: [
    'py-16',
    //
  ].join(' '),
  title: [
    'mb-10',
    'md:mb-12',
    'lg:mb-18',
    'xl:mb-20',
    'text-center',
    'font-serif-display',
    'uppercase',
    'leading-tight',
    'tracking-tight',
    'text-red-100',
    'text-3xl',
    'sm:text-4xl',
    'lg:text-6xl',
    'xl:text-7xl',
  ].join(' '),
}

class Legal extends Component {
  componentDidMount() {
    const { setHeaderTheme } = this.props
    setHeaderTheme('gray')
  }

  render() {
    const { data, pageContext } = this.props
    const { lang } = pageContext
    const { content, title } = data.page.data
    const { fields } = data.page
    const { pagePath } = fields
    return (
      <>
        <Head title={title.text} path={pagePath} />
        <div className={classes.container}>
          <h1 className={classes.title}>{title.text}</h1>
          <LegalContent html={content.html} />
        </div>
        <Footer theme='gray' lang={lang} positionStatic />
      </>
    )
  }
}

Legal.propTypes = {
  data: PropTypes.object.isRequired,
  setHeaderTheme: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    setHeaderTheme: theme => dispatch(setHeaderTheme(theme)),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(onPageRender(Legal))

export const query = graphql`
  query($id: String!) {
    page: prismicLegal(id: { eq: $id }) {
      data {
        title {
          text
        }
        content {
          html
        }
      }
      fields {
        pagePath
      }
    }
  }
`
